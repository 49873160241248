import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import implant from '../../../images/Implant_with_Quarter.png'

const metaTags = {
  description: 'Learn about treatment with SUPPRELIN® LA (histrelin acetate) subcutaneous implant. Side effects may occur, view Important Safety Information.',
  keywords: 'Homepage',
  title: 'What Is SUPPRELIN® LA (histrelin acetate)?'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>The only FDA-approved, 12-month CPP treatment </h1>
        <h2>Only the SUPPRELIN<sup>&reg;</sup> LA implant provides your child with 1 year of hormone medicine, histrelin acetate</h2>
        <ul className='brand-list'>
            <li>
                A single dose of SUPPRELIN<sup>&reg;</sup> LA provides 1 full year of medication in 1 implant to help provide hormone suppression through Month 12 after the first month of therapy
            </li>
            <li>
                The implant needs to be removed after 12 months. A new implant can be inserted if you, your family, and your child’s healthcare professional decide to continue treatment
            </li>
            <li>
                In a clinical trial, 97% of families continued with SUPPRELIN<sup>&reg;</sup> LA when a healthcare professional recommended further central precocious puberty (CPP) treatment for their child
            </li>
        </ul>
      </Col>
    </Row>
    <Row>
        <Col xs={12} >
            <h2>SUPPRELIN<sup>&reg;</sup> LA is a small, flexible implant </h2>
            <img src={implant} alt="Implant Size" title="Implant Size" />
            <h2>Your child must have regular visits with his or her pediatric endocrinologist while undergoing treatment for CPP</h2>
            <p>A healthcare professional will perform regular exams and blood tests to check for signs of puberty, measure height and weight, and may take wrist X-rays to track bone growth.</p>
        </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
      <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=3"
          LinkCaption="Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA"
        >
          Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
